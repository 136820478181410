import { Division } from 'assets/constants/enums';

export class User {
	constructor(
		public _id: string,
		public email: string,
		public employeeName: string,
		public employeeBranch: string,
		public employeeDefaultAccount: string,
		public agentLogsAccess: string,
		public agentUsersAccess: string,
		public agentSalesAccess: string,
		public agentSubscriptionsAccess: string,
		public agentDispatchAccess?: string,
		public agentDashboardAccess?: string
	) {}
}

export class UserX {
	public _id: string;
	public email: string;
	public firstname: string;
	public lastname: string;
	public status: string;
	public roles: Array<string>;
	public password: string;
	public branch: string;
	public defaultAccount: string;
	public defaultDivision: Division;
	public userImage: string;
	public account: string;
	public selectedRoles?: Array<string>;
	public audaraId?: string;

	constructor();
	constructor(user?: UserX);
	constructor(user?: any) {
		if (!user) return;
		this._id = user._id;
		this.email = user.email;
		this.firstname = user.firstname;
		this.lastname = user.lastname;
		this.status = user.status;
		this.roles = user.roles;
		this.password = user.password;
		this.branch = user.branch;
		this.defaultAccount = user.defaultAccount;
		this.defaultDivision = user.defaultDivision;
		this.userImage = user.userImage;
		this.account = user.account;
		this.selectedRoles = user.selectedRoles;
		this.audaraId = user.audaraId;
	}

	public hasRole(key: string): boolean {
		const role: string = this.roles.find((_role: string) => _role === key);
		return role !== undefined && role !== null;
	}

	public getFullName(): string {
		let fullName = '';
		if (this?.firstname) {
			fullName += this?.firstname;
		}
		if (this?.lastname) {
			if (fullName) {
				fullName += ' ';
			}
			fullName += this?.lastname;
		}
		return fullName;
	}
}

export interface ISimpleUser {
	_id: string;
	firstname: string;
	lastname: string;
}
