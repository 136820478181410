import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { RoleService } from '../services/role';

export const canActivateRole: CanActivateFn = (route, state): true | UrlTree => {
	const roleService = inject(RoleService);
	const router = inject(Router);

	// Extract required roles from route data
	const requiredRoles = (route.data['roles'] as string[]) || [];
	if (!requiredRoles.length) {
		return router.createUrlTree(['/noaccess']);
	}

	if (roleService.hasRoles(requiredRoles)) {
		return true;
	}

	// Redirect if user lacks the required roles
	return router.createUrlTree(['/noaccess']);
};
