import { ResourcesConfig } from 'aws-amplify';
import { environment } from 'environments/environment';

interface AuthPoolConfig {
	userPoolId: string;
	userPoolClientId: string;
}

const pools: Record<string, AuthPoolConfig> = {
	user: {
		userPoolId: environment.AWS_USER_POOL_ID,
		userPoolClientId: environment.COGNITO_CLIENT_ID,
	},
};

export const configureAuth: () => ResourcesConfig = () => {
	return {
		Auth: {
			Cognito: {
				userPoolId: pools.user.userPoolId,
				userPoolClientId: pools.user.userPoolClientId,
				identityPoolId: '',
				loginWith: {
					email: true,
				},
				mfa: {
					status: 'off',
					smsEnabled: false,
				},
				userAttributes: {
					email: {
						required: true,
					},
				},
				allowGuestAccess: false,
				passwordFormat: {
					minLength: 8,
					requireLowercase: true,
					requireUppercase: true,
					requireNumbers: true,
					requireSpecialCharacters: true,
				},
			},
		},
	};
};
