import { PaymentMethods } from 'modules/core/services/payments/types';
import { environment } from '../../environments/environment';
import { Filter } from 'modules/management/constants';

export const TAX_PR: number = 0;
export const TAX_PR_UVA: number = 0.04;
export const TAX_PA: number = 0.07;
export const TAX_CR: number = 0.13;
export const TAX_MX: number = 0.16;
export const TAX_CO: number = 0.19;

export const SF_ON_DEMAND_PR: string = '0011Y00002oECd5QAG';
export const SF_ON_DEMAND_PA: string = '0011Y00002PMSrWQAX';
export const SF_ON_DEMAND_CR: string = '0011Y00002qa7FiQAI';
export const SF_ON_DEMAND_CO: string = '0014z00001j4OmnAAE';
export const SF_ON_DEMAND_MX: string = 'connect-mexico-ondemand';
export const SF_AUTOCHILANGO: string = 'autochilango';
export const SF_EPARK: string = 'epark';

export const SF_ON_DEMAND_HOME_PR: string = '0011Y00002qa6bSQAQ';
export const SF_CONNECT_HOME_SERVICES_PR: string = '001i000000ImV4uAAF';
export const SF_MUNILY_ACCOUNT = '0014z00001lCiSK';
export const CONNECT_ASSISTANCE_PR_PROVIDER = 'a221Y000008TAWu';
export const SF_WHIRLPOOL = 'whirlpool';

export const HOME_PUBLIC_TOKEN = environment.HOME_PUBLIC_TOKEN;
export const ROAD_PUBLIC_TOKEN = environment.ROAD_PUBLIC_TOKEN;
export const ATH_MOVIL_ENV = environment.athMovilEnv;

export const ON_DEMAND_ACCOUNTS: any = [
	SF_ON_DEMAND_PR,
	SF_ON_DEMAND_PA,
	SF_ON_DEMAND_CR,
	SF_ON_DEMAND_CO,
	SF_ON_DEMAND_HOME_PR,
	SF_MUNILY_ACCOUNT,
	SF_ON_DEMAND_MX,
	SF_AUTOCHILANGO,
	SF_EPARK,
];

export const EXTERNAL_ON_DEMAND_ACCOUNT: string[] = [SF_AUTOCHILANGO, SF_EPARK];

/**
 * @deprecated Use enum ServiceStatus instead
 */
export const SERVICE_STATUS = {
	ACTIVE: 'Active',
	AUDIT: 'Audit',
	CANCELLED: 'Cancelled',
	FINISHED: 'Finished',
	HOLD: 'Hold',
	HOLD_DELETED: 'Hold Deleted',
	HOLD_INSPECTION: 'HoldInspection',
	NOT_COVERED: 'Not Covered',
	QUEUED: 'Queued',
	VOID: 'Void',
};

export enum ServiceStatus {
	Active = 'Active',
	Audit = 'Audit',
	Cancelled = 'Cancelled',
	Finished = 'Finished',
	Hold = 'Hold',
	HoldDeleted = 'Hold Deleted',
	HoldInspection = 'HoldInspection',
	NotCovered = 'Not Covered',
	Informative = 'Informative',
	Queued = 'Queued',
	Void = 'Void',
}

export const TRIP_STATUS_LABELS = {
	CANCELLED_BY_DRIVER: 'Cancelled by Driver',
	TOWED: 'Towed',
};

export enum TripStatus {
	Accepted = 'accepted',
	Arrived = 'arrived',
	Cancelled = 'cancelled',
	CancelledByDriver = 'cancelled_by_driver',
	Finished = 'finished',
	New = 'new',
	OnRoute = 'on_route',
	PendingAudit = 'pending_audit',
	Towed = 'towed',
}

export const CUSTOMER_PWA_USERS = ['app@connect.pr']; // to use with other customer pwas

export enum AccountSfId {
	ANTILLES_ROAD = '0011Y00002iENCTQA4',
	ANTILLES_HOME = '0011Y00002iENCxQAO',
	CSM_HOME = '001i000001unHKaAAM',
	CSM_ROAD = '001i000001unHJNAA2',
	CSM_SALVAMENTO = '0011Y00002M4vZoQAJ',
	CSM_SURVEYS = '0011Y00002kvgPPQAY',
	CSM_INSPECTIONS = '0011Y00002knIpGQAU',
	INSPECCIONES_CSM = '0011Y00002knIpGQAU',
	MULTINATIONAL_HOME = '0011Y00002hBVumQAG',
	MULTINATIONAL_ROAD = '0011Y00002hBW0QQAW',
	SSS_HOME = '0011Y00002gu1uSQAQ',
	SSS_ROAD = '0011Y00002gu1kxQAA',
	SSS_VIAJE = '0011Y00002iCyTUQA0',
	PREMIER = '001i000002DyYjmAAF',
	PREMIER_INSURANCE_COMPANY = '0014z00001gTPvbAAG',
	QBE_OPTIMA_ROAD = '001i000000CnZ8uAAF',
	QBE_OPTIMA_HOME = '001i000001DxFNPAA3',
	USIC_HOME = '0011Y00002Fn7S2QAJ',

	IS = '0011Y00002NLneWQAT',
	NASE = '0011Y000035olaGQAQ',
	SURA = '0011Y00002noEzWQAU',

	ASSA = '0011Y00002oar29QAA',
	LAFISE = '0011Y00002oar2dQAA',
	OCEANICA = '0011Y00002oar2YQAQ',
	QUALITAS = '0011Y00002c1UyJQAU',

	USIC = '0011Y00002Fn7S2QAJ',
	VELOX24 = '001i000000JEbhbAAD',
	EPISCOPAL_ROAD = '001i0000020xuSzAAI',

	INTERNACIONAL_DE_SEGUROS = '0011Y00002NLneWQAT',
	GENERAL_DE_SEGUROS = '0011Y00002cJ6c6QAC',
	FIRSTMEDICAL_AUTO = '0011Y00002p0riQQAQ',
	FIRSTMEDICAL_HOME = '0011Y00002p0rcsQAA',
	FIRSTMEDICAL_VIAJE = '0011Y00002p0rhwQAA',

	FURIEL = '001i0000024fr1TAAQ',
	MITSUBISHI = '0011Y00002Tz5lLQAR',
	PREPA = '001i0000019Gv7zAAC',
	DELTA_DENTAL = '0011Y00002HW5LTQA1',
	HYUNDAI = '001i000000CnZ49AAF',
	HYUNDAI_RENTALS = '001i000000ImGGYAA3',
	VOLVO = '001i0000026Pgy7AAC',
	MAZDA = '001i0000008Ers3AAC',
	MMM = '0014z00001ftMZxAAM',
	MMM_EMPLEADOS = '0014z00001fmBJ4AAM',

	SOS_VIP = '0011Y00002c1V0tQAE',
	SOS_TRANSPORTES_VIP = '0011Y00002oar2JQAQ',
	SOS_PYMES = '0011Y00002oar37QAA',
	SOS_BAC = '0011Y00002oar32QAA',
	SOS_CREDOMATIC = '0011Y00002c1UzMQAU',
	SOS_MEDICA = '0011Y00002c1V0UQAU',
	OPTIMA_SEGUROS = 'optima-seguros',
	ALD_AUTOMOTIVE = 'ald-automotive',
	HDI_MASCOTAS_GENERALES = 'hdi-mascotas-y-generales',
	QUALITAS_COLOMBIA = 'qualitas-colombia',
	MULTINATIONAL_INSURANCE_COMPANY = 'multinational-insurance-company',
	ALFRED_COL = 'alfred',
	VANTI_COL = '0014z00001hHaFWAA0',
	HDI_COL = '0014z00001if2fiAAA',
	BAC_PLANILLAS = '0011Y00002oar3CQAQ',
	SEGUROS_MUNDIAL = '0014z00001gRsm1AAC',
	AON = '0014z00001i8l2hAAA',
	CARDIF = '0014z00001hHaEYAA0',
	SURAMERICANA = '0014z00001frqAoAAI',
}

export const PAYMENT_METHODS_DESCRIPTION: { [key in PaymentMethods]: string } = {
	cash: 'Cash',
	card: 'Credit Card',
	gift: 'Gift',
	ath_movil: 'ATH Móvil',
	billable: 'Billable',
	vpos: 'VPos',
	credit: 'Credit Card',
	ath_business: 'Ath Business',
	yappy: 'Yappy',
	ach: 'ACH',
	conekta: 'Conekta',
	managed_by_account: 'Managed By Account',
};

export const enum APPS {
	EOS = 'eos',
	HELIOS = 'helios',
}

export const CR_INACTIVE_STATUSES = ['NO PROCEDE', 'INACTIVO', 'EXPIRED', 'INACTIVE'];

export const VIRTUAL_INSPECTOR = ['a221Y00000C0B85QAF', 'a221Y00000C0GviQAF', 'a221Y00000C0GvnQAF', 'NOSFID_VI_COL'];
export const VIRTUAL_INSPECTOR_BRANCHES = {
	CR: 'a221Y00000C0GvnQAF',
	PR: 'a221Y00000C0GviQAF',
	PA: 'a221Y00000C0B85QAF',
	CO: 'NOSFID_VI_COL',
};
export const VIRTUAL_INSPECTOR_NOT_ACCEPTED_REASONS = [
	'Prefieren Inspector',
	'No tienen data/internet',
	'No son muy tecnológicos',
	'Desconfianza en el proceso con I.V.',
];

export const STRIPE_PR_KEY = environment.stripeKeyPR;
export const STRIPE_PA_KEY = environment.stripeKeyPA;
export const STRIPE_CR_KEY = environment.stripeKeyCR;
export const STRIPE_PR_HOME_KEY = environment.stripeKeyPRHome;
export const STRIPE_MX_KEY = environment.stripeKeyMX;

export const DEFAULT_ACCOUNT_BY_BRANCH = {
	'Costa Rica': SF_ON_DEMAND_CR,
	'Puerto Rico': SF_ON_DEMAND_PR,
	Panama: SF_ON_DEMAND_PA,
	Colombia: SF_ON_DEMAND_CO,
};

// ISO Alpha 3 code is used by: Inspection form
export const BRANCH_CODE_BY_ISO_ALPHA_3 = {
	'Costa Rica': 'CRC',
	'Puerto Rico': 'PRI',
	Panama: 'PTY', // PTY is not a real ISO Alpha 3, but it is the only one that works for now (see openInspectionForm() implementation)
	Colombia: 'COL',
} as { [key: string]: string };

export enum GeoTextStatus {
	NOT_SENT = 'not_sent',
	SENT = 'sent',
	ACCEPTED = 'accepted',
	FAILED = 'failed',
}

export enum EditOrDuplicateStatus {
	EDIT = 'edit',
	FINISH_AND_NEW = 'finishAndNew',
}

// Esta lista es de salesforce's de cuentas que en caso de ser servicios de tipo Follow Up o Warranty, su price rate debe ser 0.
export const AccountsWithFollowUpOrWarrantyPriceRateZero = ['windmar'];
export const billableAffiliatedAccounts = ['otium'];

export const billableCampaign = ['ACE - Cliente en tienda'];
export const CHECK_IN_REMINDER_REASONS: Filter[] = [
	{
		content: 'Pendiente cliente',
		selected: false,
	},
	{
		content: 'Pendiente proveedor',
		selected: false,
	},
	{
		content: 'Escalamiento interno',
		selected: false,
	},
	{
		content: 'Servicio en prueba',
		selected: false,
	},
];

export const PERSON_DESTINATION = [
	{ value: 'HOME', label: 'Casa' },
	{ value: 'WORK', label: 'Trabajo' },
	{ value: 'APPOINTMENT', label: 'Cita' },
	{ value: 'ACTIVITY', label: 'Actividad o plan' },
];

export const TRUSTED_DOMAINS = environment.TRUSTED_DOMAINS?.split(',')
	.filter(Boolean)
	.map((domain) => domain.trim().toLowerCase());

export const ERROR_MESSAGES = {
	SERVICE_LIMIT: {
		title: 'Límite de Servicios',
		getSubtitle: (messageData: {
			firstname: string;
			lastname: string;
			excedeedServiceMessage: { message: string }[];
		}) =>
			`<b>${messageData?.firstname || ''} ${messageData?.lastname || ''}</b>, vemos que ya has consumido ${
				messageData?.excedeedServiceMessage?.length
					? `los <b>${messageData?.excedeedServiceMessage[0]?.message?.replace(/\D/g, '')}</b> servicios disponibles`
					: 'el limite de servicios disponible'
			} dentro de la cubierta del plan. Podemos ofrecerte esta asistencia a un costo preferencial para ayudarte a solucionar la situación.`,
		confirmButton: 'Continuar con On Demand',
		logMessage: 'Affiliated reached maximum services allowed.',
	},
	INACTIVE_CLIENT: {
		title: 'Cliente Inactivo',
		getSubtitle: (messageData: { fullname: string; expiration_date: Date; expiration_date_locale: string }) =>
			`<b>${messageData?.fullname}</b>, tu plan aparece inactivo${
				messageData?.expiration_date ? ` expiró el <b>${messageData?.expiration_date_locale}</b>` : ''
			}. Podemos ofrecerte esta asistencia a un costo preferencial para ayudarte a solucionar la situación`,
		confirmButton: 'Continuar con On Demand',
		logMessage: 'Affiliated is inactive.',
	},
};
