// External imports
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Global imports
import { environment } from 'environments/environment';
import { ServiceService } from 'modules/core/services/api/service.service';
import { GlobalStateService } from 'modules/core/services/state/global-state';
import { IncomingCallSocketService } from '../../../../../modules/core/services/sockets/incoming-call-socket.service';

// Local imports
import { IActions } from './models/actions';
import { SearchesService } from './services/searches.service';
import { SearchResults } from './types';
import { EncryptedLocalStorageService } from 'modules/core/services/storage';
import { handleError } from 'modules/shared/util';
import { Filter } from 'modules/management/constants';
import { DivisionsDropdownList, BranchesDropdownList } from './constants';
import { AuthenticationService } from 'modules/core/services/auth';
import { ServiceQNavigationService } from 'modules/dashboard/service-q-navigation.service';
import { Division } from 'assets/constants/enums';
import { Branch } from 'assets/models/Branch';
import { signOut } from 'aws-amplify/auth';

@Component({
	selector: 'rb-nav-menu',
	templateUrl: './nav-menu.component.html',
	styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit, OnDestroy {
	totalServicesCount: number = 0;
	actions: IActions[] = [];
	public showNavbar: boolean;
	public homeDropdown: boolean;
	public dispatchViewFix: string;
	public previousRoute: string = '';
	public currentRoute: string = '';
	public qParams: object;
	public urgency = 'Urgent';

	results: SearchResults = {
		services: [],
		contacts: [],
	};
	searchText: string;
	public textInCurrentSearch: string;
	public searching: boolean = false;
	private serviceCountPollingId: NodeJS.Timeout;
	showContainer: boolean = false;
	subject: Subject<any> = new Subject();
	agentDispatchAccess: boolean;
	showNotificationSettings: boolean = false;

	selectedDivision: string;
	selectedCountry: string;

	readonly divisionsList: Filter[] = DivisionsDropdownList;
	readonly branchesList: Filter[] = BranchesDropdownList;

	constructor(
		public storage: EncryptedLocalStorageService,
		public router: Router,
		public route: ActivatedRoute,
		private sService: ServiceService,
		private searchesService: SearchesService,
		public globalStateService: GlobalStateService,
		private incomingCallSocketService: IncomingCallSocketService,
		private authService: AuthenticationService,
		private dashboardNavigationService: ServiceQNavigationService
	) {}

	ngOnInit(): void {
		this.initializeNotificationFilters();
		merge(this.storage.observe('employeeBranch'), this.storage.observe('employeeDivision')).subscribe(() => {
			this.selectedCountry = this.storage.retrieve('employeeBranch');
			this.selectedDivision = this.storage.retrieve('employeeDivision');
		});

		this.router.events.subscribe((val: Event) => {
			if (val instanceof NavigationEnd) {
				this.showContainer = false;
				this.previousRoute = this.currentRoute;
				this.currentRoute = val.url;
				if (this.previousRoute.toLowerCase().includes('login')) {
					this.handleLoginRoute();
				}
				this.handleNavbarVisibility();
				this.handleHomeDropdown();
				this.handleDispatchViewFix();
			}
		});

		this.getAccesses();
		this.getServiceCount().catch((error) => {
			handleError(error, '[Nav Menu Component] getServiceCount()');
		});
		this.startServiceCountPolling();
		this.initIncomingCallSocketService();
		this.subscribeToSearchSubject();

		this.authService.checkAuthAndRedirect();
	}

	private handleLoginRoute(): void {
		this.getAccesses();
		this.getServiceCount().catch((error) => {
			handleError(error, '[Nav Menu Component] getServiceCount()');
		});
		this.startServiceCountPolling();
		if (this.storage.secureRetrieve('audaraId')) {
			this.initIncomingCallSocketService();
		}
	}

	private handleNavbarVisibility(): void {
		const lowerCaseUrl = this.router.url.toLowerCase();
		if (lowerCaseUrl.includes('login') || lowerCaseUrl.includes('resetpassword')) {
			this.stopServiceCountPolling();
			this.showNavbar = false;
		} else {
			this.showNavbar = true;
		}
	}

	private handleHomeDropdown(): void {
		this.homeDropdown = false;
	}

	private handleDispatchViewFix(): void {
		this.dispatchViewFix = this.router.url.includes('dispatch') ? 'fixed' : 'unset';
	}

	private initIncomingCallSocketService(): void {
		if (this.storage.secureRetrieve('audaraId')) {
			this.incomingCallSocketService.init();
		}
	}

	private subscribeToSearchSubject(): void {
		this.subject.pipe(debounceTime(1000)).subscribe(() => {
			this.search(this.searchText).catch((error) => {
				handleError(error, '[Nav Menu Component] search()');
			});
		});
	}

	private stopServiceCountPolling(): void {
		if (this.serviceCountPollingId) clearInterval(this.serviceCountPollingId);
	}

	private startServiceCountPolling(): void {
		this.stopServiceCountPolling();
		this.serviceCountPollingId = setInterval(() => {
			this.getServiceCount().catch((error) => {
				handleError(error, '[Nav Menu Component] getServiceCount()');
			});
		}, 45000);
	}

	public async getServiceCount(): Promise<void> {
		const body: any = {
			branch: this.selectedCountry,
			serviceType: this.selectedDivision,
			urgency: this.urgency,
		};
		const response = await this.sService.getServicesCount(body);
		if (response.status) {
			this.totalServicesCount = response.data.total;
		}
	}

	public getAccesses(): void {
		this.actions = [];
		if (this.storage.secureRetrieve('agentDispatchAccess')) {
			this.actions.push(
				{
					urlImage: 'assets/images/dashboard_btn.png',
					urlRedirect: '/dispatch/open',
					redirectType: 'internal',
					ariaLabel: 'Open Dispatch',
				},
				{
					urlImage: 'assets/images/speedometer_icon.png',
					urlRedirect: '/dashboard',
					redirectType: 'internal',
					ariaLabel: 'Open Dashboard',
				}
			);
		}
		if (this.storage.secureRetrieve('agentSalesAccess')) {
			this.actions.push({
				urlImage: 'assets/images/sale_tag_icon.png',
				urlRedirect: `${environment.salesClientLink}/login/${this.storage.secureRetrieve('token')}`,
				redirectType: 'external',
				ariaLabel: 'Open Sales',
			});
		}
		if (this.storage.secureRetrieve('agentUsersAccess')) {
			this.actions.push({
				urlImage: 'assets/images/users_icon.png',
				urlRedirect: '/management/users',
				redirectType: 'internal',
				ariaLabel: 'Open Users Access',
			});
		}
		this.actions.push({
			urlImage: 'assets/images/back_icon.png',
			urlRedirect: '/login',
			redirectType: 'logout',
			ariaLabel: 'Open Logout',
		});
		this.agentDispatchAccess = this.storage.secureRetrieve('agentDispatchAccess');
	}

	async redirect(action: IActions): Promise<void> {
		switch (action.redirectType) {
			case 'internal':
				this.router.navigate([action.urlRedirect]);
				break;
			case 'external':
				window.open(action.urlRedirect, '_blank');
				break;
			default:
				await this.logout();
				break;
		}
	}
	async logout(): Promise<void> {
		const loginType = this.storage.retrieve('loginType');

		this.storage.clear();
		this.incomingCallSocketService.close();
		this.authService.stopTokenMonitor();

		switch (loginType) {
			case 'google':
				this.authService.logoutWithGoogle();
				break;
			case 'cognito':
				await signOut();
				this.router.navigate(['/login']);
				break;
			default:
				console.warn('Tipo de inicio de sesión no reconocido:', loginType);
		}
	}
	async search($string: string): Promise<any> {
		if ($string.length > 3 && this.textInCurrentSearch !== $string) {
			this.textInCurrentSearch = $string;
			this.searching = true;

			this.results = await this.searchesService.getResults({
				search: $string,
				branch: this.globalStateService.getBranch(),
			});
			this.searching = false;
			this.showContainer = true;
		}
	}
	onFocus(): void {
		this.showContainer = false;
	}

	searchService(poNumber: string): void {
		this.router.navigate(['/service', poNumber]);
		this.searchText = '';
	}
	onKeyUp(e): void {
		this.showContainer = false;
		this.clearDataResults();

		if (e.code !== 'Enter') {
			this.subject.next(this.searchText);
		} else {
			this.textInCurrentSearch = '';
		}
	}
	clearDataResults(): void {
		this.results = {
			services: [],
			contacts: [],
		};
	}
	clearInput(): void {
		this.searchText = '';
		this.showContainer = false;
		this.clearDataResults();
	}
	ngOnDestroy(): void {
		this.incomingCallSocketService.close();
		this.stopServiceCountPolling();
	}

	openNotificationSettingsModal(): void {
		this.showNotificationSettings = true;
	}

	closeNotificationSettingsModal(): void {
		this.showNotificationSettings = false;
	}

	submitNotificationSettings(): void {
		this.storage.store('division', this.selectedDivision);
		this.storage.store('country', this.selectedCountry);
		this.getServiceCount();
		this.closeNotificationSettingsModal();
	}

	onDivisionChange(event: any) {
		this.selectedDivision = event.value;
	}

	onCountryChange(event: any) {
		this.selectedCountry = event.value;
	}

	onNotificationClick() {
		const baseQueryParams = {
			branch: this.selectedCountry as Branch,
			division: this.selectedDivision as Division,
		};

		// Fills the dashboards filters for service q
		this.storage.store('selectedBranches', JSON.stringify([this.selectedCountry]));
		this.storage.store('selectedDivisions', JSON.stringify([this.selectedDivision]));

		const queryParams = this.dashboardNavigationService.getServiceQParams(baseQueryParams);
		// Navigate to Service Q Dashboard with the appropriate query parameters
		this.router.navigate(['/dashboard/service_q'], {
			queryParams,
			replaceUrl: true,
		});
	}

	initializeNotificationFilters() {
		const storedCountry = this.storage.retrieve('country');
		const branch = this.globalStateService.getBranch();
		this.selectedCountry = storedCountry || branch || 'Global';

		const storedDivision = this.storage.retrieve('division');
		const employeeDivision = this.storage.retrieve('employeeDivision');
		this.selectedDivision = storedDivision || employeeDivision || 'all';
	}
}
