// External imports
import { RouterModule, Routes } from '@angular/router';

// Global imports
import { AuthGuard, UnAuthGuard } from 'modules/core/guards/auth';
import { GlobalStateResolver } from './resolvers/global-state.resolver';
import { serverTimeResolver } from './resolvers/server-time.resolver';
import { canActivateRole } from './guards/role-guard';

// Local imports

const APP_ROUTES: Routes = [
	{
		path: 'login',
		loadChildren: () => import('modules/login').then((m) => m.LoginModule),
		canActivate: [UnAuthGuard],
	},
	{
		path: 'dispatch/:mongoIdIfHold',
		resolve: {
			globalStateInitialized: GlobalStateResolver,
		},
		loadChildren: () => import('modules/dispatch').then((m) => m.DispatchModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'dashboard',
		resolve: {
			globalStateInitialized: GlobalStateResolver,
			serverTime: serverTimeResolver,
		},
		canActivate: [AuthGuard],
		loadChildren: () => import('modules/dashboard').then((m) => m.DashboardModule),
	},
	{
		path: 'migration',
		resolve: {
			globalStateInitialized: GlobalStateResolver,
		},
		loadChildren: () => import('modules/migration').then((m) => m.MigrationModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'noaccess',
		resolve: {
			globalStateInitialized: GlobalStateResolver,
		},
		loadChildren: () => import('modules/noaccess').then((m) => m.NoaccessModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'service',
		resolve: {
			globalStateInitialized: GlobalStateResolver,
		},
		canActivate: [AuthGuard],
		loadChildren: () => import('modules/service').then((m) => m.ServiceModule),
	},
	{
		path: 'management',
		loadChildren: () => import('modules/management').then((m) => m.ManagementModule),
	},
	{
		path: 'block-schedule',
		resolve: {
			globalStateInitialized: GlobalStateResolver,
		},
		canActivate: [AuthGuard],
		loadChildren: () => import('modules/block-schedule').then((m) => m.BlockScheduleModule),
	},
	{
		path: 'auto-processing',
		canActivate: [AuthGuard, canActivateRole],
		data: { roles: ['view_auto_processing_dashboard'] },
		loadComponent: () =>
			import('modules/auto-processing/auto-processing-page.component').then((c) => c.AutoProcessingPageComponent),
	},
	{ path: '**', pathMatch: 'full', redirectTo: '/dashboard/active' },
];

export const AppRoutingModule = RouterModule.forRoot(APP_ROUTES, {
	enableTracing: false,
	onSameUrlNavigation: 'reload',
});
