import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './modules/core';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { Amplify } from 'aws-amplify';
import { configureAuth } from 'amplify/auth';

Sentry.init({
	dsn: 'https://b52972dfe88b49b2b5a490329dea7155@o298640.ingest.sentry.io/1553144',
	environment: environment.ENV_NAME,
	debug: !environment.production,
	// Set sample rate to 1.0 to capture 100% of transactions or errors.
	sampleRate: Number(environment.SENTRY_ERROR_SAMPLING_RATE),
	tracesSampleRate: Number(environment.SENTRY_TRANSACTION_SAMPLING_RATE),
});

Amplify.configure(configureAuth());

if (environment.production) {
	enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule);
