// External imports
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

// Local imports
import { UserX } from 'assets/models/User';

@Injectable({
	providedIn: 'root',
})
export class RoleService {
	constructor(private storage: LocalStorageService) {}

	public hasRoles(roles: string[]) {
		const user = new UserX(this.storage.retrieve('user'));
		return roles.filter((role) => user.hasRole(role)).length > 0;
	}

	public canViewMigrationScreen() {
		const allowedRoles = ['rol_upload'];
		return this.hasRoles(allowedRoles);
	}

	public canViewAutoProcessingDashboard() {
		const allowedRoles = ['view_auto_processing_dashboard'];
		return this.hasRoles(allowedRoles);
	}

	public canAuditService() {
		const allowedRoles = ['role_supervisor'];
		return this.hasRoles(allowedRoles);
	}

	/**
	 * Function that determines if the user
	 * has the correct role to use the routing service
	 * @returns a boolean value
	 */
	public canScheduleManuallyRouting() {
		const allowedRoles = ['manual_calendar'];
		return this.hasRoles(allowedRoles);
	}

	/**
	 * > Checks if the user can create a warranty.
	 * @returns true if they can create a warranty, false otherwise
	 */
	public canCreateWarranty() {
		const allowedRoles = ['role_supervisor', 'coordinator'];
		return this.hasRoles(allowedRoles);
	}

	/**
	 * > Checks if the user can create a warranty even if the warranty has expired.
	 * @returns true if they can create a warranty, false otherwise
	 */
	public canCreateExpiredWarranty() {
		const allowedRoles = ['role_supervisor'];
		return this.hasRoles(allowedRoles);
	}

	/**
	 * If the user has the role of supervisor or coordinator, then they can create a follow up
	 * @returns A boolean value.
	 */
	public canCreateFollowUp() {
		const allowedRoles = ['role_supervisor', 'coordinator'];
		return this.hasRoles(allowedRoles);
	}

	/**
	 * If the user has the role of supervisor, then they can create an expired follow up
	 * @returns A boolean value.
	 */
	public canCreateExpiredFollowUp() {
		const allowedRoles = ['role_supervisor'];
		return this.hasRoles(allowedRoles);
	}

	public canEditProviderWeightType() {
		const allowedRoles = ['role_supervisor', 'edit_provider_payment'];
		return this.hasRoles(allowedRoles);
	}

	public canEditProviderCosts() {
		const allowedRoles = ['role_supervisor', 'edit_provider_payment'];
		return this.hasRoles(allowedRoles);
	}

	public canEditServiceCost() {
		const allowedRoles = ['role_supervisor', 'edit_provider_payment'];
		return this.hasRoles(allowedRoles);
	}

	public canEditLocalityType() {
		const allowedRoles = ['role_supervisor', 'edit_provider_payment'];
		return this.hasRoles(allowedRoles);
	}

	public hasWarrantyClassificationRole(): boolean {
		const allowedRoles = ['warranty_classification'];
		return this.hasRoles(allowedRoles);
	}

	/**
	 * If the user has the role of Account Manager, then they can create and edit Accounts and PolicyLimits
	 * @returns A boolean value.
	 */

	public canModifyAccounts() {
		const allowedRoles = ['account_manager'];
		return this.hasRoles(allowedRoles);
	}
}
